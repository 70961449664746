<template>
    <div>
        <Header />

        <VueSlickCarousel v-bind="settingsHero" class="carouselHero">
            <div class="slider-item">
                <span class="text h1 font-weight-bold">En Fundación Rebeca Lan<br>vemos la reinserción con otros ojos,<br>¡súmate hoy mismo!</span>
                <img src="@/assets/img/hero-home/hero-01.jpg" alt="" title="">
            </div>
            <div class="slider-item">
                <span class="text h1 font-weight-bold">Su vida es oscura,<br>pero con tu participación y donativos<br>podemos dignificar las cárceles de mujeres<br>habilitando bibliotecas.</span>
                <img src="@/assets/img/hero-home/hero-02.jpg" alt="" title="">
            </div>
            <div class="slider-item">
                <span class="text h1 font-weight-bold">En México hay más de 10,500 mujeres<br>privadas de la libertad que <br>merecen otra oportunidad de sanar emocionalmente.</span>
                <img src="@/assets/img/hero-home/hero-03.jpg" alt="" title="">
            </div>
            <div class="slider-item">
                <span class="text h1 font-weight-bold">Empoderamos a las mujeres privadas de la libertad<br>como constructoras de su futuro,<br>enseñándolas a creer en sí mismas,<br>apoyándolas a crear su proyecto de vida.</span>
                <img src="@/assets/img/hero-home/hero-04.jpg" alt="" title="">
            </div>
            <div class="slider-item">
                <span class="text h1 font-weight-bold">El 95% de las mujeres que salen de prisión<br>no pueden conseguir un empleo.<br>Es por eso que trabajamos en su formación<br>para abrirles una oportunidad laboral.</span>
                <img src="@/assets/img/hero-home/hero-05.jpg" alt="" title="">
            </div>
            <div class="slider-item">
                <span class="text h1 font-weight-bold">Conoce sus historias...<br>85% de las mujeres privadas de la libertad<br>son madres de niños que viven dentro y fuera de prisión.</span>
                <img src="@/assets/img/hero-home/hero-06.jpg" alt="" title="">
            </div>
        </VueSlickCarousel>


        <div id="ayudanos" class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col text-center">
                        <h2 class="pb-3 mb-4"><b>¡Ayúdanos!</b></h2>
                        <p class="text-center">A brindarles una oportunidad a las mujeres que han sido privadas de la libertad.<br>
                        A reinsertarlas de manera positiva en la vida productiva de la sociedad.<br>
                        A que sus hijos cambien los círculos de violencias por los de amor.<br>
                        A contribuir mejorar la seguridad en México.</p>
                        <p class="mb-5 text-center"><b>Juntos… podemos hacer más.</b></p>
                        <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=4DA37QSD4LTV2" target="_blank" class="btn btn-secret">DONA AQUÍ</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="contenido py-5">
            <div class="container">
                <div class="row">
                    <div class="col">

                        <div class="row">
                            <div class="col text-center">
                                <div class="card bg-secret bordered card-video">
                                    <div class="card-video-container">
                                        <div class="card-video_video">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/8dKq43jQ-G8?controls=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="prensa">
                            <div class="row">
                                <div class="col py-5">
                                    <h2 class="bg-secret d-inline-block py-1 px-4 text-uppercase h4 font-weight-bold mb-3">prensa</h2>

                                    <VueSlickCarousel v-bind="settingsPrensa" class="carouselPrensa">
                                        <div><a href="https://www.excelsior.com.mx/nacional/fundacion-rebeca-lan-intensifica-labor-en-penales/1512963" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_excelsior.jpg`" class="card-img"></a></div>
                                        <div><a href="https://www.milenio.com/policia/cdmx-festejan-nino-menores-santa-martha-acatitla/" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_milenio.jpg`" class="card-img"></a></div>
                                        <div><a href="https://www.elsoldemexico.com.mx/circulos/fundacion-rebeca-lan-busca-dar-voz-a-mujeres-reclusas-8109803.html" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_soldemexico.jpg`" class="card-img"></a></div>
                                        <div><a href="https://aztecaamerica.com/2022/03/15/fundacion-rebeca-lan-y-hipgive-buscan-reunir-a-mujeres-privadas-de-la-libertad-con-sus-hijos/" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_azteca.jpg`" class="card-img"></a></div>
                                        <div><a href="https://www.elimparcial.com/tijuana/tijuana/Fundacion-Rebeca-Lan-apoya-a-mujeres-en-prision--20220307-0033.html" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_imparcial.jpg`" class="card-img"></a></div>

                                        <div><a href="https://www.la-prensa.com.mx/metropoli/mujeres-reclusas-enfrentan-doble-castigo-fundacion-rebeca-lan-7982640.html" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_laprensa.jpg`" class="card-img"></a></div>
                                        <div><a href="https://revistacosas.mx/rebeca-lan-una-fundacion-preocupada-por-la-integracion-femenina/" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_cosas2.jpg`" class="card-img"></a></div>
                                        <div><a href="https://revistacosas.mx/fundacion-rebeca-lan-conmemora-el-dia-internacional-de-la-mujer-a-traves-del-empoderamiento-de-mujeres-en-prision/" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_cosas.jpg`" class="card-img"></a></div>
                                        <div><a href="https://www.informador.mx/estilo/Fundacion-Rebeca-Lan-una-esperanza-hacia-la-reinsercion-social-20210404-0027.html/" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_informador.jpg`" class="card-img"></a></div>
                                        <div><a href="https://www.uniradioinforma.com/noticias/bajacalifornia/652280/con-colecta-milagro-navideno-buscan-ayudar-a-mujeres-detenidas.html" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_uniradioinforma.jpg`" class="card-img"></a></div>

                                        <div><a href="http://www.afntijuana.info/informacion_general/124135_llevaran_la_navidad_a_mujeres_en_la_penitenciaria_de_tijuana" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_afntijuana.jpg`" class="card-img"></a></div>
                                        <div><a href="https://8columnas.com.mx/cultura/inauguran-bibliotecas-en-el-penal-femenil-de-ecatepec-y-quinta-del-bosque/" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_8columnas.jpg`" class="card-img"></a></div>
                                        <div><a href="https://www.reforma.com/aplicacioneslibre/preacceso/articulo/default.aspx?__rval=1&urlredirect=https://www.reforma.com/tienden-mano-a-reclusas/ar2133820?referer=--7d616165662f3a3a6262623b727a7a7279703b767a783a--" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_reforma.jpg`" class="card-img"></a></div>
                                        <div><a href="https://www.youtube.com/watch?v=pW-m8EaClkc" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_informativo6.jpg`" class="card-img"></a></div>
                                        <div><a href="https://www.youtube.com/watch?v=kWFonOkQxV4" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_noticias7am.jpg`" class="card-img"></a></div>

                                        <div><a href="https://www.facebook.com/watch/?v=1118976748938370" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_grupoformula.jpg`" class="card-img"></a></div>
                                        <div><a href="http://tijuanainformativo.info/index.php/noticias-de-tijuana/item/120440-acompana-cedhbc-a-fundacion-rebeca-lan-durante-campana-quiero-ir-a-la-escuela" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_tijuanainformativo.jpg`" class="card-img"></a></div>
                                        <div><a href="https://www.notired.mx/nota.pl?id=506" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_notired.jpg`" class="card-img"></a></div>
                                        <div><a href="https://edomexaldia.com/fundacion-rebeca-lan-logra-reunir-articulos-de-higiene-personal-para-las-mujeres-privadas-de-su-libertad-a-traves-de-la-colecta-milagro-navideno-2021/" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_edomexaldia.jpg`" class="card-img"></a></div>
                                        <div><a href="https://arzatenoticias.com/index.php/2022/03/15/sin-visita-de-familiares-90-de-mujeres-encarceladas/" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_arzatenoticias.jpg`" class="card-img"></a></div>

                                        <div><a href="https://mx.hola.com/cine/2016052914118/fernanda-castillo-visita-mujeres-penal/" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_rev1.jpg`" class="card-img"></a></div>
                                        <div><a href="https://www.elimparcial.com/espectaculos/Cuando-Denisse-de-Kalafe-fue-a-la-carcel...-por-el-Dia-de-las-Madres-20200510-0037.html" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_rev2.jpg`" class="card-img"></a></div>
                                        <div><a href="https://www.uniradioinforma.com/noticias/tijuana/496447/reinauguran-bibliotecas-del-cereso-de-tijuana.html" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_uniradio.jpg`" class="card-img"></a></div>
                                        <div><a href="https://www.eluniversal.com.mx/articulo/metropoli/cdmx/2016/11/30/internas-de-santa-martha-acatitla-reestrenan-biblioteca" target="_blank" class="card shadow m-3"><img :src="`${publicPath}storage/prensa/prensa_universal.jpg`" class="card-img"></a></div>

                                    </VueSlickCarousel>

                                </div>
                            </div>
                        </div>

                        <div id="donantes">
                            <div class="row">
                                <div class="col py-5">
                                    <h2 class="bg-secret d-inline-block py-1 px-4 text-uppercase h4 font-weight-bold mb-3">donantes y alianzas</h2>

                                    <VueSlickCarousel v-bind="settingsDonantes" class="carouselDonantes">


                                        <div><a target="_blank" href="https://www.grupocem.edu.mx/"><img src="@/assets/img/donantes/donante-centro-ericksoniano.png" class="img-fluid"></a></div>
                                        <div><a target="_blank" href="https://www.blindajesepel.com/"><img src="@/assets/img/donantes/donante-blindajes-epel.jpg" class="img-fluid"></a></div>
                                        <div><a target="_blank" href="https://hmhamburguesas.com/"><img src="@/assets/img/donantes/donante-hm-hamburguesas.jpg" class="img-fluid"></a></div>
                                        <div><a target="_blank" href="https://www.steren.com.mx/steren-tijuana"><img src="@/assets/img/donantes/donante-steren-tijuana.jpg" class="img-fluid"></a></div>
                                        <div><a target="_blank" href="https://kempler.com.mx/kemplerx"><img src="@/assets/img/donantes/donante-kempler.jpg" class="img-fluid"></a></div>
                                        <div><a target="_blank" href="https://tijuana.ibero.mx/"><img src="@/assets/img/donantes/donante-ibero.png" class="img-fluid"></a></div>
                                        <div><a target="_blank" href="https://www.mokacosmetics.com/"><img src="@/assets/img/donantes/donante-moka.png" class="img-fluid"></a></div>
                                        <div><a target="_blank" href="https://revistamujeractual.com/"><img src="@/assets/img/donantes/donante-mujer-actual.jpeg" class="img-fluid"></a></div>
                                        <div><div><img src="@/assets/img/donantes/donante-aimo.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-anahuac-mexico-norte.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-anahuac.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-areas.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-ayuntamiento-tijuana.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-bellydance-evolution.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-brigadas-de-belleza.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-camafeo.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-cdi.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-cecati.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-ceinre.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-centro-anciano.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-chrismas.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-ciudadana.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-comision-dh-baja-california.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-costco.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-cruz-roja.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-dif-tijuana.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-dulce-leche.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-edomex.jpg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-el-mexicano.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-el-sazon.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-ethnofit.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-extermimex.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-fam.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-fantasy-land.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-fundacion-codet.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-fvpbc.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-gandhi.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-gobierno-cdmx.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-heraldo.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-icbc.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-icd.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-idip.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-imac.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-imef.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-immujer.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-instituto-avant-garde.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-intercontinental.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-julia-gonzalez.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-jump-in.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-justicia-social-tijuana.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-kadima.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-ken-jewish.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-lady-multitask.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-manos-entrelazadas.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-masters-barber.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-mexico.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-mishmash.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-mona.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-novelty.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-otra-ronda.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-party-land.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-radio-centro.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-radioformula.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-ricardos.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-sol-de-mexico.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-sol-toluca.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-ssp-baja-california.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-sublime-milenio.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-susy-pasteleria.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-tijuana.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-tips.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-trepsi.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-tv-azteca.png" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-udci.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-unifront.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-universidad-xochicalco.jpeg" class="img-fluid"></div></div>
                                        <div><div><img src="@/assets/img/donantes/donante-waldos.png" class="img-fluid"></div></div>

                                        <div><a target="_blank" href="https://www.farmaciaslamasbarata.com/" ><img src="@/assets/img/donantes/donante-farmacia-la-mas-barata.png" class="img-fluid "></a></div>
                                        <div><a target="_blank" href="https://rendichicas.com/"><img src="@/assets/img/donantes/donante-rendichicas.png" class="img-fluid"></a></div>
                                    </VueSlickCarousel>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div id="informes" class="informes py-5">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="card bg-coal shadow p-5 card-informe">
                            <div class="row">
                                <div class="col offset-md-3 text-center text-md-left">
                                    <h2 class="font-weight-bold h5">Informes anuales</h2>
                                    <p>Descarga nuestros informes completos.</p>
                                    <a href="storage/informes/fundacion-rebeca-lan-informe-2016.pdf" target="_blank" class="btn btn-secret btn-md mr-2 mb-2">2016</a>
                                    <a href="storage/informes/fundacion-rebeca-lan-informe-2017.pdf" target="_blank" class="btn btn-secret btn-md mr-2 mb-2">2017</a>
                                    <a href="storage/informes/fundacion-rebeca-lan-informe-2018.pdf" target="_blank" class="btn btn-secret btn-md mr-2 mb-2">2018</a>
                                    <a href="storage/informes/fundacion-rebeca-lan-informe-2019.pdf" target="_blank" class="btn btn-secret btn-md mr-2 mb-2">2019</a>
                                    <a href="storage/informes/fundacion-rebeca-lan-informe-2020.pdf" target="_blank" class="btn btn-secret btn-md mr-2 mb-2">2020</a>
                                    <a href="storage/informes/fundacion-rebeca-lan-informe-2021.pdf" target="_blank" class="btn btn-secret btn-md mr-2 mb-2">2021</a>
                                    <a href="storage/informes/fundacion-rebeca-lan-informe-2023.pdf" target="_blank" class="btn btn-secret btn-md mr-2 mb-2">2023</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<style lang="less">
    @import "@/assets/css/colors.less";
    @import "@/assets/css/main.less";
    @import "./css/home.less";
    .bg-dark{
        background-image: url('../../assets/img/bg-image.jpg')
    }
</style>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import Header from '@/modules/Templates/Header'
import Footer from '@/modules/Templates/Footer'

export default {
    name: 'Home',
    data () {
      return {
            publicPath: process.env.BASE_URL,
            settingsPrensa: {
                infinite: false,
                slidesToShow: 4,
                slidesToScroll: 2,
                prevArrow: '<button type="button" class="slick-prev"><img src="@/assets/img/angle-left-light.svg"></button>',
                nextArrow: '<button type="button" class="slick-next"><img src="@/assets/img/angle-right-light.svg"></button>',
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: '40px',
                        slidesToShow: 2
                    }
                    },
                    {
                    breakpoint: 576,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: '40px',
                        slidesToShow: 1
                    }
                }]
            },
            settingsDonantes: {
                infinite: true,
                slidesToShow: 5,
                slidesToScroll: 5,
                variableWidth: true,
                centerMode: true,
                autoplay: true,
                responsive: [{
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: '40px',
                        slidesToShow: 2
                    }
                    },
                    {
                    breakpoint: 576,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: '40px',
                        slidesToShow: 1
                    }
                }]
            },
            settingsHero: {
                arrows: false,
                dots: true,
                autoplay: true,
                autoplaySpeed: 4800
            }
      }
    },
    mounted(){
        if(window.location.hash) {
            const id = window.location.hash.substring(1);
            const yourElement = document.getElementById(id);
            const y = yourElement.getBoundingClientRect().top + window.pageYOffset;

            window.scrollTo({top: y, behavior: 'smooth'});
        }
    },
    methods: {

    },
    components:{
        Header,
        Footer,
        VueSlickCarousel
    }
};
</script>